<template>
  <div class="class-list" v-loading="loading">
    <div class="taber_scoll dis al_c">
      <div
        :class="topIndex == item.id ? 'selectStyle' : 'ts_center'"
        @click="selectOnheight(item.id)"
        v-for="item in scollData"
        :key="item.id"
      >
        <span style="margin-right: 5px">{{ item.name }}</span>
        <!-- <span class="center_sapn">({{ item.num }})</span> -->
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.title"
          placement="bottom"
          ><i class="el-icon-warning-outline"></i
        ></el-tooltip>
      </div>
    </div>
    <GlobalChunk>
      <div class="top_center">
        <div class="button_top">
          <div class="faultBtn">
            <div
              :class="auditStatus === '-1' ? 'active' : 'none'"
              @click="btnClick('-1')"
            >
              全部<span v-if="auditStatus === '-1'"
                >({{ applyNumber || 0 }})</span
              >
            </div>
            <div
              v-if="topIndex == 1"
              :class="auditStatus === '00' ? 'active' : 'none'"
              @click="btnClick('00')"
            >
              待确认<span v-if="auditStatus === '00'"
                >({{ applyNumber || 0 }})</span
              >
            </div>
            <div
              :class="auditStatus === '01' ? 'active' : 'none'"
              @click="btnClick('01')"
            >
              已成交<span v-if="auditStatus === '01'"
                >({{ applyNumber || 0 }})</span
              >
            </div>
            <div
              v-if="topIndex == 1"
              :class="auditStatus === '10' ? 'active' : 'none'"
              @click="btnClick('10')"
            >
              已取消<span v-if="auditStatus === '10'"
                >({{ applyNumber || 0 }})</span
              >
            </div>
            <div
              :class="auditStatus === '30' ? 'active' : 'none'"
              @click="btnClick('30')"
            >
              已退单<span v-if="auditStatus === '30'"
                >({{ applyNumber || 0 }})</span
              >
            </div>
          </div>
        </div>
      </div>
      <GlobalForm
        :init-data="initData"
        :form-item-list="formItemList"
        :inline="true"
        :labelWidth="80"
        round
        :needBtnLoading="true"
        @handleChange="handleChange"
        @handleConfirm="handleConfirm"
        confirmBtnName="查询"
      >
        <el-form-item
          label="订单号"
          slot="orderNo"
          style="margin: 0"
          label-width="70px"
        >
          <el-input v-model="orderNo" placeholder="请输入订单号查询" clearable>
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="topIndex == 2"
          class="el_from"
          label="活动名称"
          slot="activityId"
          style="margin: 0"
        >
          <el-select
            v-model="activityId"
            placeholder="请先选择门店商家"
            clearable
            :disabled="alldisabled"
            filterable
          >
            <el-option
              v-for="item in activityoption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="topIndex == 2"
          class="el_from"
          label="商品名称"
          slot="productId"
          style="margin: 0"
        >
          <el-select
            :disabled="alldisabled"
            v-model="productId"
            placeholder="请先选择门店商家"
            clearable
            filterable
          >
            <el-option
              v-for="item in productoption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="topIndex == 2"
          class="el_from"
          label="成交时间"
          slot="signTime"
          style="margin: 0px"
        >
          <el-date-picker
            v-model="signTime"
            type="daterange"
            start-placeholder="开始日期"
            range-separator="~"
            :picker-options="pickerOptions"
            end-placeholder="结束日期"
            clearable
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          v-if="topIndex == 1"
          class="el_from"
          label="下单时间"
          slot="cancelTime"
          style="margin: 0px"
        >
          <el-date-picker
            v-model="signTime"
            type="daterange"
            start-placeholder="开始日期"
            range-separator="~"
            :picker-options="pickerOptions"
            end-placeholder="结束日期"
            clearable
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="订单状态"
          slot="stateSelect"
          style="margin: 0"
        >
          <el-select
            v-model="state"
            placeholder="请选择订单状态"
            clearable
            multiple
            filterable
          >
            <el-option
              v-for="item in stateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="topIndex == 2"
          class="el_from"
          label="顾客姓名"
          slot="customerName"
          style="margin: 0"
          label-width="80px"
        >
          <el-input
            v-model="customerName"
            clearable
            placeholder="请输入顾客姓名查询"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="topIndex == 2"
          class="el_from"
          label="顾客手机号"
          slot="customerPhone"
          style="margin: 0"
          label-width="90px"
        >
          <el-input
            v-model="customerPhone"
            clearable
            placeholder="请输入顾客手机号查询"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="topIndex == 2"
          class="el_from"
          label="补贴方式"
          slot="subsidyWay"
          style="margin: 0"
        >
          <el-select
            v-model="subsidyWay"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in subsidyoption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div slot="AddSlot">
          <!-- <el-button
            size="small"
            type="warning"
            icon="el-icon-download"
            :loading="serching"
            @click="importDetailExcel"
            >导出商品明细</el-button
          > -->
          <el-button
            size="small"
            type="success"
            icon="el-icon-download"
            :loading="isExecl"
            @click="clickExcel"
            >导出Excel</el-button
          >
        </div>
      </GlobalForm>
      <div class="filterLeft">
        <el-checkbox v-model="isFilterStock">筛选有效录音</el-checkbox>
        <el-checkbox v-model="criticizeState">筛选未完成录音评价订单</el-checkbox>
      </div>
      <div class="GlobalTable">
        <div v-if="seachDataList.length > 0">
          <div
            class="table"
            v-for="(item, index) in seachDataList"
            :key="index"
          >
            <div class="table_header">
              <div class="table_header_left">
                <p class="table_index">{{ index + 1 }}</p>
                <p>
                  下单商户：<span>{{ item.createCompanyName }}</span>
                </p>
                <!-- <p>下单店员：{{ item.staffName }}</p> -->
                <p>成交时间：{{ item.ackTime || "--" }}</p>
                <p>参与活动：{{ item.activityName }}</p>
                <p>
                  订单状态：{{
                    item.state == "00"
                      ? "待确认"
                      : item.state == "01"
                      ? "已确认"
                      : item.state == "02"
                      ? "待支付"
                      : item.state == "03"
                      ? "已绑码"
                      : item.state == "04"
                      ? "已收货"
                      : item.state == "05"
                      ? "降价收货"
                      : item.state == "10"
                      ? "已取消"
                      : item.state == "20"
                      ? "已作废"
                      : "已退回"
                  }}
                </p>
                <p>录音是否评价：{{ item.criticizeState=='01' ? "是" : "否" }}</p>
              </div>
              <div class="table_header_right"  v-if="topIndex == 2">
                <div class="bicycle_lane" v-if="item.subsidyWay == '00'">
                  旧机加价
                </div>
                <div class="bicycle_lane Star_support" v-else>换新抵扣</div>
              </div>
            </div>
            <div>
              <el-table
                :data="[item]"
                border
                style="width: 100%"
                id="excelOrder"
              >
                <el-table-column label="回收订单号" align="center">
                  <template slot-scope="{ row }">
                    <div class="underline" @click="goOrderDetail(row)">
                      {{ row.orderNo }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="下单门店" align="center">
                  <template slot-scope="{ row }">
                    <div>{{ row.storeName }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="下单店员" align="center">
                  <template slot-scope="{ row }">
                    <div>{{ row.staffName }}-{{ row.staffMobile }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="回收机型" align="center">
                  <template slot-scope="{ row }">
                    <div>{{ row.phoneName }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="换新顾客信息"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <span
                      v-if="row.customerName == '' && row.customerPhone == ''"
                      >--</span
                    >
                    <span v-else>{{ row.customerName }}</span
                    ><span v-if="row.customerPhone"
                      >-{{ row.customerPhone }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="赠送商品"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div class="underline" @click="noteFn(row, true)">
                      {{ row.giftNum }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="赠送价值"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.giftCost }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="换新商品"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div class="underline" @click="noteFn(row, false)">
                      {{ row.productNum }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="换新补贴金额"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.subsidyPrice }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="回收价"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.quotePrice }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="顾客成交价"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.finalPrice }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="顾客成交价（含补贴）"
                  width="140px"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div class="wrap">
                      <span>{{ row.afterSubsidyFinalPrice }}</span>
                      <p style="color: #ff687b" v-if="row.subsidyWay == '00'">
                        含{{ row.subsidyPrice }}补贴
                      </p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="回收总毛利"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.allRebateAmount }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="员工奖励"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.staffReward }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="公司回收净利"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.netRebateAmount }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="总压价金额"
                  v-if="topIndex == 2"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.allCutPrice || "0" }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="是否计入开口"
                  width="110px"
                  align="center"
                >
                  <template slot="header">
                    <div class="img-el-heard" style="display: flex">
                      <div>是否计入开口</div>
                      <div>
                        <el-tooltip
                          effect="dark"
                          content="完成录音时长超过10秒，则本默认计入开口，标记为无效则自动剔除"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                      </div>
                    </div>
                  </template>
                  <template slot-scope="{ row }">
                    <span>{{ row.isCountingOpen ? "是" : "否" }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="是否录音" align="center">
                  <template slot-scope="{ row }">
                    <span>{{
                      row.isSoundRecording ? "已完成" : "未完成"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="录音评价" align="center">
                  <template slot-scope="{ row }">
                    <div>
                      {{
                        row.criticize == "invalid"
                          ? "无效录音"
                          : row.criticize == "unqualified"
                          ? "不合格"
                          : row.criticize == "qualified"
                          ? "合格"
                          : row.criticize == "fine"
                          ? "优秀"
                          : "--"
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  width="200"
                  label="操作"
                  fixed="right"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div v-if="row.isSoundRecording">
                      <el-button
                        size="mini"
                        type="primary"
                        plain
                        @click="evaluateAction(row, 'remark')"
                        >录音详情</el-button
                      >
                      <!-- <el-button
                      v-else
                      size="mini"
                      type="primary"
                      plain
                      @click="evaluateAction(row, 'remark')"
                      >查看录音</el-button
                    > -->
                    </div>
                    <span v-else>--</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="orderPagina">
            <el-pagination
              background
              :current-page="page.pageNum"
              :page-size="10"
              layout="total, prev, pager, next,jumper"
              :total="page.total"
              class="el-pagination-c"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
        <div v-else class="emptyOrder">暂无订单~</div>
        <!-- 录音评价 -->
        <el-dialog
          title="录音评价"
          :visible.sync="commandShow"
          :close-on-click-modal="false"
          width="600px"
          @closed="commandClosed"
        >
          <div class="luying_cen">
            <span style="margin-right: 50px"
              >订单号：{{ Soundinfo.orderNo }}</span
            >
            <span>下单时间：{{ Soundinfo.orderTime }}</span>
          </div>
          <div class="luying_cen">
            <span
              >下单店员：{{ Soundinfo.staffName }}-{{
                Soundinfo.staffMobile
              }}</span
            >
            <span style="margin: 0 5px"
              ><el-button type="success" size="mini">
                {{
                  Soundinfo.staffIsMajor
                    ? "创建人"
                    : Soundinfo.staffType == "01"
                    ? "子账号"
                    : Soundinfo.staffType == "02"
                    ? "总监"
                    : Soundinfo.staffType == "03"
                    ? "店长"
                    : "店员"
                }}</el-button
              ></span
            >
            <span v-if="Soundinfo.storeName"
              >（{{ Soundinfo.storeName }}）</span
            >
          </div>
          <div class="luying_cen">
            <span>参与换新活动：{{ Soundinfo.activityName ||"--"}}</span>
          </div>
          <div class="lu_content">
            <p style="font-weight: bold; font-size: 15px">录音内容</p>
            <p style="margin: 10px 0">
              录音结束时间：{{ Soundinfo.soundRecordingTime }}
            </p>
            <audio
              style="width: 300px; height: 30px"
              ref="audioPlayer"
              preload="metadata"
              :src="Soundinfo.soundRecordingLink"
              controls
            ></audio>
          </div>
          <div class="lu_content" v-if="Soundinfo.criticizeRemark">
            <p style="font-weight: bold; font-size: 15px; margin-bottom: 10px">
              录音评价
            </p>
            <div class="taber_scoll dis al_c">
              <div
                :class="
                  evaluateIndex === item.id ? 'evaluateStyle' : 'evaluate_center'
                "
                @click="evaluateheight(item.id)"
                v-for="item in evaluateData"
                :key="item.id"
              >
                <span>{{ item.name }}</span>
              </div>
            </div>
            <!-- <div class="qb_act">
              <div class="qukliy_box">照片拍摄清晰完整，棒棒的！</div>
              <div class="qukliy_box">使用系统检测报价更高哦~</div>
            </div> -->
            <!-- <div>
              <el-input
                :autosize="{ minRows: 2, maxRows: 4 }"
                maxlength="200"
                type="textarea"
                placeholder="请填写更多备注内容，最多200字"
                v-model="Soundinfo.criticizeRemark"
              ></el-input>
            </div> -->
            <div>
              <p style="font-weight: bold; font-size: 15px; margin-bottom: 5px">
                评价内容:
              </p>
              <p>
                {{ Soundinfo.criticizeRemark }}
              </p>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click.native="commandShow = false">取消</el-button>
            <el-button type="primary" @click.native="usedSuccess('remark')"
              >确定</el-button
            >
          </span>
        </el-dialog>
        <!-- 换新商品列表 -->
        <el-dialog
          title="换新商品列表"
          :visible.sync="noteShow"
          :close-on-click-modal="false"
          width="980px"
        >
          <GlobalTable
            ref="GlobalTable"
            v-loading="noteLoading"
            :columns="noteList"
            :data="noteData"
            :maxHeight="700"
            :isPagination="false"
          >
            <el-table-column
              label="换新补贴金额"
              slot="subsidyPrice"
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row.isGift ? row.sellPrice : row.subsidyPrice }}</span>
                <p v-if="!row.isGift">
                  <el-tag type="danger" size="mini">{{
                    row.subsidyWay == "00" ? "旧机加价" : "换新抵扣"
                  }}</el-tag>
                </p>
                <p v-else>
                  <el-tag type="danger" size="mini">赠品全额抵扣</el-tag>
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="购买凭证"
              width="120px"
              slot="voucher"
              align="center"
            >
              <template slot-scope="{ row }">
                <img
                  style="width: 100px; height: 80px"
                  :src="row.voucher"
                  @click="seeSampleImage(row.voucher)"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
          label="商品卖点"
          slot="Commoditybuy"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              style="
                color: #0981ff;
                cursor: pointer;
                text-decoration: underline;
              "
              @click="seesellingPointList(row.sellPointViews)"
            >
              查看
            </p>
          </template>
        </el-table-column>
          </GlobalTable>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="noteShow = false">关 闭</el-button>
          </span>
        </el-dialog>
        <!-- 查看卖点 -->
    <el-dialog
      title="卖点详情"
      :visible.sync="prductsDialogVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <div style="margin-bottom: 20px">
        <div
          v-for="(item, index) in sellingPointList"
          :key="index"
          class="selDIAN"
        >
          <img :src="item.sellingPointIcon" alt="" />
          <div>
            <p>{{ item.sellingPointTitle }}</p>
            <span>{{ item.detailed || "暂无" }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="prductsDialogVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
        <!-- 查看图片 -->
        <el-image-viewer
          :zIndex="99999"
          v-if="showViewer"
          :on-close="
            () => {
              imgList = [];
              showViewer = false;
            }
          "
          :url-list="imgList"
        />
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  name: "class-list",
  components: { ElImageViewer },
  data() {
    return {
      sellingPointList:[],
      PerfectreviewData: {
        joinCause: "",
      },
      commandShow: false,
      customerName: "",
      customerPhone: "",
      subsidyWay: "",
      isFilterStock: "",
      criticizeState:"",
      topIndex: 1, //
      evaluateIndex: "", //
      evaluateData: [
        {
          name: "无效录音",
          value: "invalid",
          id: 1,
        },
        {
          name: "优秀",
          value: "fine",
          id: 2,
        },
        {
          name: "合格",
          value: "qualified",
          id: 3,
        },
        {
          name: "不合格",
          value: "unqualified",
          id: 4,
        },
      ],
      scollData: [
        {
          name: "未换购",
          num: 1,
          title: "包含未成交订单和已成交未换购订单",
          id: 1,
        },
        {
          name: "已换购",
          num: 1,
          title: "参加换购的订单，包含已成交和已退单订单",
          id: 2,
        },
      ],
      auditStatus: "-1",
      applyNumber: 0,
      imgList: [],
      showViewer: false,
      loading: false,
      noteLoading: false,
      noteShow: false,
      noteList: [
        { label: "商品编码", prop: "productCode" },
        { label: "换新商品", prop: "productName" },
        { label: "实售价", prop: "realSellPrice" },
        // {label: "换新补贴金额", prop: "subsidyPrice"},
        { slotName: "subsidyPrice" },
        { label: "商品销售价", prop: "sellPrice" },
        { label: "商品成本", prop: "cost" },
        { label: "新机串号", prop: "imei" },
        { slotName: "voucher" }, //购买凭证
        { slotName: "Commoditybuy" }, 
      ],
      noteData: [],
      seachDataList: [],
      entryTime: null,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumns: [
        { label: "商家名称", prop: "createCompanyName" },
        { slotName: "orderNo" }, //回收订单号
        { slotName: "storeName" },
        { slotName: "staffName" },
        { label: "成交时间", prop: "ackTime" },
        { slotName: "phoneName" },
        { slotName: "state" },
        { label: "参与活动", prop: "activityName" },
        { label: "换新补贴金额", prop: "subsidyPrice" },
        { slotName: "productNum" }, //换新商品
        { label: "回收价", prop: "quotePrice" },
        { slotName: "afterSubsidyQuotePrice" }, //补贴后回收价
        { slotName: "afterSubsidyFinalPrice" }, //顾客成交价
        { label: "回收总毛利", prop: "allRebateAmount" },
        { label: "员工奖励", prop: "staffReward" },
        { label: "公司回收净利", prop: "netRebateAmount" },
        { slotName: "customerName" }, //换新顾客信息
        { label: "换新商品售价", prop: "productSellPrice" },
        { label: "换新商品成本", prop: "productCost" },
        { slotName: "settlementMethod" }, //补贴结算方式
      ],
      isExecl: false,
      serching: false,
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day31 ||
              time.getTime() < this.pickerMinDate - this.day31
            );
          }
          return false;
        },
      },
      state: [],
      subsidyoption: [
        {
          value: "00",
          label: "旧机加价",
        },
        {
          value: "01",
          label: "换新抵扣",
        },
      ],
      stateOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "待确认",
          value: "00",
        },
        {
          label: "已确认",
          value: "01",
        },
        {
          label: "待支付",
          value: "02",
        },
        {
          label: "已绑码",
          value: "03",
        },
        {
          label: "已收货",
          value: "04",
        },
        {
          label: "降价收货",
          value: "05",
        },
        {
          label: "已取消",
          value: "10",
        },
        {
          label: "已作废",
          value: "20",
        },
        {
          label: "已退回",
          value: "30",
        },
      ],
      initData: {
        entryTime: [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      orderNo: "",
      signTime: [
        moment()
          .day(moment().day() - 30)
          .format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD 23:59:59"),
      ],
      cancelTime: [
        moment()
          .day(moment().day() - 30)
          .format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD 23:59:59"),
      ],
      SeachParams: {},
      formItemList: [
        {
          labelName: "所属商家",
          key: "companyId",
          childrenKey: "storeId",
          childrenKey2: "inspectorId",
          childrenKey3: "activityId",
          childrenKey4: "productId",
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          lableWidth: "78px",
          clear: true,
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "下单门店",
          fatherKey: "companyId",
          key: "storeId",
          type: "selectFilterable",
          placeholder: "请先选择门店商家",
          lableWidth: "78px",
          clear: true,
          disabled: true,
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "区域/总监",
          fatherKey: "companyId",
          key: "inspectorId",
          type: "selectFilterable",
          placeholder: "请先选择门店商家",
          lableWidth: "80px",
          clear: true,
          disabled: true,
          option: [],
          clearFun: () => false,
        },
        { slotName: "activityId" },
        { slotName: "productId" },
        {
          key: "staffName",
          type: "input",
          labelName: "下单店员",
          placeholder: "请输入下单店员姓名查询",
        },
        { slotName: "signTime" },
        { slotName: "cancelTime" },
        { slotName: "orderNo" },
        { slotName: "stateSelect" },
        { slotName: "customerName" },
        { slotName: "customerPhone" },
        { slotName: "subsidyWay" },
        {
          key: "isCountingOpen",
          type: "select",
          lableWidth: "80px",
          labelName: "是否开口",
          placeholder: "请选择",
          option: [
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "isSoundRecording",
          type: "select",
          lableWidth: "80px",
          labelName: "是否录音",
          placeholder: "请选择",
          option: [
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "criticize",
          type: "select",
          lableWidth: "80px",
          labelName: "录音评价",
          placeholder: "请选择",
          option: [
            {
              value: "invalid",
              label: "无效录音",
            },
            {
              value: "fine",
              label: "优秀",
            },
            {
              value: "qualified",
              label: "合格",
            },
            {
              value: "unqualified",
              label: "不合格",
            },
          ],
        },
      ],
      activityId: "",
      productId: "",
      productoption: [],
      activityoption: [],
      alldisabled: true,
      Soundinfo: {},
      prductsDialogVisible:false,
    };
  },
  created() {
    let companyId = Number(this.$route.query.companyId);
    let activityId = Number(this.$route.query.activityId);
    let orderNo = this.$route.query.orderNo;
    if (activityId) {
      this.initData.companyId = companyId;
      this.activityId = activityId;
      this.orderNo = orderNo;
      this.initData.entryTime = null;
      this.handleChange({ key: "companyId" }, companyId);
    }
    this.getMerchantSelect();
    this.handleConfirm(this.initData);
  },
  watch: {
    isFilterStock(val) {
      this.isFilterStock = val;
      this.handleCurrentChange(1);
    },
    criticizeState(val) {
      this.criticizeState = val;
      this.handleCurrentChange(1);
    },
  },
  methods: {
    seesellingPointList(list) {
      this.sellingPointList = list;
      this.prductsDialogVisible = true;
    },
    evaluateAction(row) {
      _api.getorderSoundinfo({ orderNo: row.orderNo }).then((res) => {
        this.Soundinfo = res.data;
        this.commandShow = true;
        this.evaluateData.forEach((item) => {
          console.log(item.value,res.data.criticize);
          if (item.value === res.data.criticize) {
            this.evaluateIndex = item.id;
          }
        });
      });
    },
    commandClosed() {},
    usedSuccess() {
      this.commandShow = false;
    },
    selectOnheight(id) {
      if (this.topIndex == id) return;
      this.topIndex = id;
      this.auditStatus = "-1";
      this.customerPhone = "";
      this.customerName = "";
      this.subsidyWay = "";
      this.seachDataList=[]
      this.handleCurrentChange(1);
    },
    evaluateheight(id) {
      if (this.evaluateIndex == id) return;
      this.evaluateIndex = id;
    },
    btnClick(type) {
      this.auditStatus = type;
      this.handleCurrentChange(1);
    },
    handleChange(v1, v2) {
      console.log(v1, v2);
      if (v1.key === "companyId") {
        const index = this.formItemList.findIndex((v) => v.key === "storeId");
        const inspectorIdIndex = this.formItemList.findIndex(
          (v) => v.key === "inspectorId"
        );
        if (v2) {
          this.formItemList[index].disabled = false;
          this.formItemList[inspectorIdIndex].disabled = false;
          this.alldisabled = false;
          this.getStoreList(v2);
          this.getInspectorId(v2);
          this.activitySelect(v2);
          this.productSelect({ companyId: v2, activityId: "" });
        } else {
          this.formItemList[index].disabled = true;
          this.formItemList[inspectorIdIndex].disabled = true;
          this.alldisabled = true;
        }
      }
    },
    // 所属商家下拉
    async getMerchantSelect() {
      const storeRes = await _api.getSelectList();
      if (storeRes.code === 1) {
        this.formItemList[0].option = storeRes.data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      }
    },
    // 请求下单门店下拉
    getStoreList(companyId) {
      _api.getStoreSelectList({ companyId }).then((r) => {
        const data = r.data || [];
        const index = this.formItemList.findIndex((v) => v.key === "storeId");
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.value,
            value: v.key,
          };
        });
      });
    },
    // 区域总监下拉
    getInspectorId(v) {
      _api.inspectorAreaSelect({ companyId: v, scope: "00" }).then((r) => {
        const data = r.data || [];
        const index = this.formItemList.findIndex(
          (v) => v.key === "inspectorId"
        );
        this.formItemList[index].option = data.map((v) => {
          return {
            value: v.staffId,
            label:
              (v.staffName ? v.staffName : "") +
              (v.areaName ? "-" + v.areaName : ""),
          };
        });
      });
    },
    // 活动下拉
    activitySelect(companyId) {
      _api.activitySelect({ companyId }).then((r) => {
        const data = r.data || [];
        this.activityoption = data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      });
    },
    //商品下拉
    productSelect(params) {
      console.log("lala", params);
      _api.productSelect(params).then((r) => {
        const data = r.data || [];
        this.productoption = data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      });
    },
    handleConfirm(data, cd) {
      console.log(data);
      let startTime = "";
      let endTime = "";
      if (this.signTime === null) {
        console.log(2);
      } else {
        startTime = moment(this.signTime[0]).format("x");
        endTime = moment(this.signTime[1]).format("x");
      }
      this.SeachParams = {
        companyId: data && data.companyId ? data.companyId : "",
        inspectorId: data && data.inspectorId ? data.inspectorId : "",
        activityId: this.activityId,
        productId: this.productId,
        storeId: data && data.storeId ? data.storeId : "",
        staffName: data && data.staffName ? data.staffName : "",
        isCountingOpen:data.isCountingOpen,
        isSoundRecording: data.isSoundRecording,
        criticize: data && data.criticize ? data.criticize : "",
        orderNo: this.orderNo,
        state: this.state,
        customerName: this.customerName,
        customerPhone: this.customerPhone,
        subsidyWay: this.subsidyWay,
        startTime: startTime,
        endTime:  endTime,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === "function") {
            cd();
          }
        },
      };
      this.handleCurrentChange();
    },
    importDetailExcel() {
      let data = {
        ...this.SeachParams,
      };
      this.serching = true;
      _api
        .excelOrderRenewproducts(data)
        .then((res) => {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName = "换新商品明细.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
          this.serching = false;
        })
        .catch((err) => {
          this.serching = false;
        });
    },
    clickExcel() {
      let data = {
        isRenewProductOrder: this.topIndex == 1 ? false : true, //	否换购订单（true是，false否）
          soundIsNormal: this.isFilterStock?'01':'', //是否有效录音（01:有效，02：无效）
          criticizeState:this.criticizeState?'00':'',//	评价状态（待评价：00，已评价：01）
        ...this.SeachParams,
      };
      this.isExecl = true;
      _api
        .excelOrderRenew(data)
        .then((res) => {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName = "换新订单.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
          this.isExecl = false;
        })
        .catch((err) => {
          this.isExecl = false;
        });
    },
    // 跳转至订单详情
    goOrderDetail(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      console.log(this.isFilterStock);
      const baseRequest = {
        isRenewProductOrder: this.topIndex == 1 ? false : true, //	否换购订单（true是，false否）
        queryState: this.auditStatus, //查询状态【全部：-1，待确认：00，已确认：01，已取消：10，已退回：30
        isCountingOpen: this.SeachParams.isCountingOpen, //是否计入开口（true是，false否）
        isSoundRecording: this.SeachParams.isSoundRecording, //是否录音（true是，false否）
        criticize: this.SeachParams.criticize, //录音评价（无效：invalid，不合格：unqualified，合格：qualified，优秀：fine）
        soundIsNormal: this.isFilterStock?'01':'', //是否有效录音（01:有效，02：无效）
        criticizeState:this.criticizeState?'00':'',//	评价状态（待评价：00，已评价：01）	

        companyId: this.SeachParams.companyId,
        inspectorId: this.SeachParams.inspectorId,
        activityId: this.SeachParams.activityId,
        productId: this.SeachParams.productId,
        storeId: this.SeachParams.storeId,
        staffName: this.SeachParams.staffName,
        orderNo: this.SeachParams.orderNo,
        state: this.SeachParams.state,
        customerName: this.SeachParams.customerName,
        customerPhone: this.SeachParams.customerPhone,
        subsidyWay: this.SeachParams.subsidyWay,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api
        .orderRenewList(baseRequest)
        .then((res) => {
          if (res.code === 1) {
            console.log(367, res);
            this.seachDataList = res.data.ipage.records;
            this.page.total = res.data.ipage.total;
            this.page.pageNum = res.data.ipage.current;
            this.loading = false;
            this.initexpressNo = "";
            this.applyNumber = res.data.ipage.total;
          }
        })
        .finally(() => {
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 换新商品数量
    noteFn(row, isGift) {
      this.noteShow = true;
      this.noteLoading = true;
      _api.findProducts({ orderNo: row.orderNo, isGift }).then((res) => {
        if (res.code == 1) {
          this.noteData = res.data;
          this.noteLoading = false;
        }
      });
    },
    // 查看示例图大图
    seeSampleImage(posterImg) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(posterImg);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list {
}
.filterLeft {
  width: 400px;
}
.taber_scoll {
  margin-bottom: 20px;
  .ts_center {
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
    width: 100px;
    height: 32px;
    background: #fdfdfe;
    border-radius: 4px;
    border: 1px solid #568bff;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #4c84ff;
    line-height: 32px;
  }
  .selectStyle {
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
    width: 100px;
    height: 32px;
    background: #4c84ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
  }
  .evaluate_center {
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
    width: 150px;
    height: 36px;
    border: 1px solid #666666;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
  }
  .evaluateStyle {
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
    width: 150px;
    height: 36px;
    // border: 1px solid #ffaf29;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    background: red;
    line-height: 36px;
  }
}
.center_sapn {
  margin: 0 5px;
}
.dis {
  display: flex;
}
.al_c {
  align-items: center;
}
.ju_s {
  justify-content: space-between;
}
.ju_c {
  justify-content: center;
}
.top_center {
  width: 100%;
  // height: 100px;
  .button_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .faultBtn {
      //width: 432px;
      height: 34px;
      // background: #f9fbfd;
      // border: 1px solid #c1d1ff;
      // box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
      border-radius: 21px;
      display: flex;
      cursor: pointer;
      .active {
        text-align: center;
        color: white;
        padding: 0 20px;
        // width: 50%;
        height: 14px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        line-height: 32px;
        height: 32px;
        color: #0981ff;
        // background: #0981ff;
        border-radius: 20px;
      }
      .none {
        text-align: center;
        color: #333;
        padding: 0 20px;
        // width: 50%;
        height: 14px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        line-height: 32px;
        height: 32px;
        border-radius: 20px;
      }
    }
  }
}
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .table {
    margin-top: 14px;

    .table_header {
      width: 100%;
      padding: 11px 0;
      padding-right: 10px;
      border-radius: 4px 4px 0px 0px;
      background: #e9f0fe;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table_header_left {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #4c84ff;
        justify-content: space-between;

        > p {
          margin-right: 30px;
        }

        .table_index {
          width: 30px;
          height: 18px;
          background: #f5b724;
          border-radius: 3px 9px 9px 3px;
          text-align: center;
          line-height: 18px;
          margin-right: 9px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .table_header_right {
        display: flex;

        .bicycle_lane {
          height: 20px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #21d8c2;
          border-radius: 10px;
          font-size: 12px;
          color: #21d8c2;
          padding: 0 11px;
          line-height: 20px;
        }

        .Star_support {
          margin-left: 10px;
          color: #ff9204;
          border: 1px solid #ffaf29;
        }
      }
    }

    /deep/ .el-table .el-table__header th {
      background: #f9fbfd !important;
      border-width: 0;
      border-bottom-width: 1px;
    }
  }
  .orderPagina {
    margin: 50px 50px 20px 0;
    text-align: right;
  }
  .autoshow::-webkit-scrollbar {
    display: none;
  }

  .emptyOrder {
    width: 100%;
    text-align: center;
    padding: 80px 0px;
    font-size: 24px;
    color: rgb(102, 102, 102);
    background: white;
    font-weight: bold;
  }
  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mr-50 {
    margin-right: 50px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .font-w {
    font-weight: bold;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex-as {
    display: flex;
    align-items: flex-start;
  }
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .underline {
    text-decoration: underline;
    color: #0981ff;
    cursor: pointer;
  }
}

.qb_act {
  display: flex;
  margin: 10px 0;

  .qukliy_box {
    font-size: 13px;
    margin-right: 10px;
    line-height: 13px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #e4ecfd;
    color: rgb(9, 129, 255);
    cursor: pointer;
  }
}
.luying_cen {
  margin-bottom: 10px;
}
.lu_content {
  margin-bottom: 10px;
  width: 100%;
  padding: 5px 15px 20px 15px;
  border: 1px solid #e4ecfd;
}
.selDIAN {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 760px;
  min-height: 50px;
  background: #f0f5ff;
  border-radius: 4px;
  img {
    width: 50px;
    height: 50px;
    margin-right: 12px;
  }
  p {
    font-family: FZLanTingHei-B-GBK;
    font-weight: 400;
    font-size: 14px;
  }
  span {
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
}
</style>